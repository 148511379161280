import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-mood',
  templateUrl: './mood.component.html',
  styleUrls: ['./mood.component.scss']
})
export class MoodComponent implements OnInit {
  @Input()
  public satisfied: string;

  @Input()
  public dissatisfied: string | undefined;

  @Input()
  public neutral: string | undefined;

  @Input()
  public notApplicable: string | undefined;

  public ngOnInit() {
    if (!this.dissatisfied) {
      this.dissatisfied = '0';
    }
    if (!this.neutral) {
      this.neutral = '0';
    }
    if (!this.notApplicable) {
      this.notApplicable = '0';
    }
  }
}
