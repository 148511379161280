import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
  Brand,
  BrandService,
  EvaluationCategory,
  EvaluationCriteria,
  EvaluationCriteriaService,
  LINK_FILE_SERVER,
  ReviewComment,
  ReviewMood,
  ReviewService,
  SurveyRespondent,
  SurveyService
} from 'lib-commons';
import {ReviewRating, ReviewType, ReviewVote} from '../../../../../lib-commons/src/lib/model/models';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-comparator-review',
  templateUrl: './review.component.html',
  styleUrls: ['review.component.scss']
})
export class ReviewComponent implements OnInit {

  public brand: Brand;
  public brandLogo: string;
  public respondent: SurveyRespondent;

  public evaluationCriteria: Promise<EvaluationCriteria[]>;
  public reviewMoodMock = ReviewMood;
  public votes: ReviewVote[];

  public inputReview = {
    rating: 0,
    name: '',
    dateMonth: '',
    dateYear: new Date().getFullYear(),
    title: '',
    comment: '',
    mood: ''
  };

  public isFormReviewValid: boolean;
  public hasFormSubmitSucceed: boolean;
  public hasFormSubmitFailed: boolean;

  private queryCategory: string;
  private queryBrandName: string;

  constructor(private cd: ChangeDetectorRef,
              private router: Router,
              private route: ActivatedRoute,
              private brandService: BrandService,
              private reviewService: ReviewService,
              private evaluationCriteriaService: EvaluationCriteriaService,
              private surveyService: SurveyService) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.queryCategory = params.category;
      this.queryBrandName = params.name;
    });

    this.inputReview.mood = ReviewMood.NEUTRAL;
    this.getEvaluationCriteria();

    this.getRespondent();
    this.getBrand();
  }

  private getRespondent() {
    this.surveyService.getRespondent(response => {
      this.respondent = response || undefined;
    });
  }

  private getBrand() {
    this.brandService.findByUniqueName(this.queryBrandName)
      .toPromise()
      .then(brand => {
        this.brand = brand;

        this.getLogo();
        this.getRespondentHistoryForBrand();
        this.cd.markForCheck();
      });
  }

  private getEvaluationCriteria() {
    this.evaluationCriteria = this.evaluationCriteriaService.findByCategory(this.queryCategory)
      .toPromise();
  }

  private getLogo() {
    this.brandService.findLogo(this.brand).then(logo => {
      this.brandLogo = logo
        ? LINK_FILE_SERVER + 'assets/images/providers/' + logo
        : null;
    });
  }

  private getRespondentHistoryForBrand() {
    // get ratings
    this.reviewService.findRatingsByBrand(this.brand.id)
      .toPromise()
      .then(reviews => {
        const data = reviews.find(elt => elt.respondent.id === this.respondent.id);
        this.inputReview.rating = data ? data.rating : 0;
        this.cd.markForCheck();
      });

    // get votes
    this.reviewService.findVotesByBrand(this.brand.id)
      .toPromise()
      .then(reviews => {
        this.votes = reviews.filter(elt => elt.respondent.id === this.respondent.id);
        this.cd.markForCheck();
      });
  }

  public reload() {
    this.router.navigate(['home'])
      .then();
  }

  public onFormReviewChange() {
    this.isFormReviewValid = (
      this.inputReview.rating > 0
      && this.inputReview.name.length >= 5
      && this.inputReview.dateMonth !== ''
      && !isNaN(this.inputReview.dateYear)
      && this.inputReview.comment.length > 30
    );
  }

  public getDateQuestion(): string {
    let question = 'Date';
    const category = this.brand
      ? this.brand.evaluationCategory.charAt(0)
        + this.brand.evaluationCategory.slice(1).toLowerCase()
      : '';
    switch (category) {
      case EvaluationCategory.HOTEL:
      case EvaluationCategory.NIGHT_LIFE:
        question = 'Date du dernier séjour';
        break;
      case EvaluationCategory.INTERNET_CALL:
        question = 'Depuis quand êtes-vous client?';
        break;
      case EvaluationCategory.RESTAURANT:
        question = 'Date de la dernière visite';
        break;
      case EvaluationCategory.TRAVEL:
        question = 'Date du dernier voyage';
        break;
    }
    return question;
  }

  public getDropdownYear(): string[] {
    const maxYear = new Date().getFullYear();
    const minYear = maxYear - 5;
    const years = [];
    for (let year = maxYear; year >= minYear; year--) {
      years.push(year);
    }
    return years;
  }

  public getDropdownMonth(): string[] {
    return [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
  }

  public isMoodActive(mood: ReviewMood): boolean {
    return (this.inputReview.mood === mood);
  }

  public getCategoryForUrl(): string {
    return this.brand ? BrandService.mapToCategoryString(this.brand) : '';
  }

  public saveReviewComment() {
    const review: ReviewComment = {
      id: undefined,
      createdAt: new Date(),
      brand: this.brand,
      respondent: this.respondent,
      reviewType: ReviewType.COMMENT,
      url: 'https://consumer-mind.net/#/brand/'
        + this.getCategoryForUrl() + '/' + this.brand.nameShort,
      source: 'Consumer Mind',
      username: this.inputReview.name,
      comment: this.inputReview.comment,
      reviewMood: this.inputReview.mood.toUpperCase(),
      lastExperienceDate: this.inputReview.dateYear + '-'
        + (Number(this.inputReview.dateMonth) < 10 ? '0' + this.inputReview.dateMonth
          : this.inputReview.dateMonth),
      title: null
    };

    if (this.inputReview.title && this.inputReview.title.length >= 10) {
      review.title = this.inputReview.title;
    }

    this.reviewService.createComment(review).then(
      response => {
        if (response.id) {
          this.isFormReviewValid = false;
          this.hasFormSubmitSucceed = true;
        } else {
          this.hasFormSubmitFailed = true;
          console.log(response);
        }
        this.cd.markForCheck();
      },
      error => {
        this.hasFormSubmitFailed = true;
        this.cd.markForCheck();
        console.log(error);
      }
    );
  }

  public saveRating(ratingScale: number) {
    const review: ReviewRating = {
      id: undefined,
      createdAt: new Date(),
      brand: this.brand,
      respondent: this.respondent,
      reviewType: ReviewType.RATING_SCALE_5,
      rating: ratingScale
    };
    this.reviewService.createRating(review).then(response => {
      if (response.id) {
        this.inputReview.rating = response.rating;
        this.cd.markForCheck();
      }
    });
  }

  public saveVote(criteria: EvaluationCriteria, value: number) {
    const review: ReviewVote = {
      id: undefined,
      createdAt: new Date(),
      brand: this.brand,
      respondent: this.respondent,
      reviewType: ReviewType.YES_NO,
      evaluationCriteria: criteria,
      rating: value,
      relatedCommentId: null
    };
    this.reviewService.createVote(review).then(response => {
      if (response.id) {
        this.votes.push(response);
        this.cd.markForCheck();
      }
    });
  }

  public matchEvaluationVoteRating(criteria: EvaluationCriteria, value: number): boolean {
    if (this.votes) {
      const vote = this.votes
        .find(elt => elt.evaluationCriteria.id === criteria.id);
      return vote ? (vote.rating === value) : false;
    }
    return false;
  }
}
