import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule} from '@angular/core';
import {LibCommonsModule, RestService, BrandService} from 'lib-commons';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeComponent} from './components/home/home.component';
import {SearchComponent} from './components/search/search.component';
import {BrandComponent} from './components/brand/brand.component';
import {MoodComponent} from './common/mood/mood.component';
import {ReviewComponent} from './components/review/review.component';
import {BrandCardComponent} from './common/brand-card/brand-card.component';
import {FormsModule} from '@angular/forms';

const COMPONENTS = [
  AppComponent,
  HomeComponent,
  SearchComponent,
  BrandComponent,
  ReviewComponent,
  BrandCardComponent,
  MoodComponent
];

@NgModule({
  declarations: COMPONENTS,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LibCommonsModule,
    FormsModule
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'en'},
    RestService,
    BrandService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
