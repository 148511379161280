import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Brand} from 'lib-commons';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-comparator-brand-card',
  templateUrl: './brand-card.component.html',
  styleUrls: []
})
export class BrandCardComponent implements OnInit {

  @Input() public brand: Brand;

  ngOnInit() {
  }
}
