import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit
} from '@angular/core';
import {Router} from '@angular/router';
import {
  Brand,
  BRAND_CATEGORIES,
  BrandService,
  LINK_FILE_SERVER
} from 'lib-commons';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-comparator-home',
  templateUrl: './home.component.html',
  styleUrls: []
})
export class HomeComponent implements OnInit {

  public URL_IMAGES = LINK_FILE_SERVER + 'assets/images/';

  public cities: Promise<string[]>;
  public categories: {
    name: string,
    image: string,
    url: string,
    brands: Brand[]
  } [];

  public searchText: string;
  public searchBrands: Promise<Brand[]>;
  public searchCities: Promise<string[]>;

  private brands: Promise<Brand[]>;

  constructor(private cd: ChangeDetectorRef,
              private router: Router,
              private brandService: BrandService) {
  }

  ngOnInit() {
    this.brands = this.brandService.findAll().toPromise();
    this.mapBrands();

    this.categories = BRAND_CATEGORIES;
  }

  private mapBrands() {
    this.brands.then(brands => {
      brands.forEach(brand => {
        const category = BrandService.mapToCategoryString(brand);
        this.pushBrandInCategory(category, brand);

        this.cd.markForCheck();
      });
    });

    this.cities = this.brandService.findAllBrandCities();
  }

  private pushBrandInCategory(brandCategory: string, brand: Brand) {
    this.categories.forEach(category => {
      if (category.url === brandCategory) {
        category.brands.push(brand);
      }
    });
  }

  private getSearchBrands(key: string): Promise<Brand[]> {
    return new Promise(resolve => {
      this.brands.then(brands => {
        resolve(
          brands.filter(brand => {
            return brand.name.toLowerCase().indexOf(key) >= 0;
          }).slice(0, 4)
        );
      });
    });
  }

  private getSearchCities(key: string): Promise<string[]> {
    return new Promise(resolve => {
      this.cities.then(cities => {
        resolve(
          cities.filter(city => {
            return city.toLowerCase().indexOf(key) >= 0;
          })
        );
      });
    });
  }

  public mapCategoryUrl(brand: Brand): string {
    return BrandService.mapToCategoryString(brand);
  }

  public isSearchEnabled(): boolean {
    return (this.searchText && this.searchText.length >= 3);
  }

  public reload() {
    this.router.navigate(['home'])
      .then();
  }

  public search(text: string) {
    this.searchText = text;
    if (text && text.length >= 3) {
      text = text.toLowerCase();
      this.searchBrands = this.getSearchBrands(text);
      this.searchCities = this.getSearchCities(text);
      this.cd.markForCheck();
    }
  }
}
