export interface Brand {
  id: string;
  name: string;
  nameShort: string;
  country: string;
  city: string;
  address: string;
  website: string;
  facebook: string;
  evaluationCategory: EvaluationCategory;
  logoUri: string;
}

export enum EvaluationCategory {
  HOTEL = 'Hotel',
  NIGHT_LIFE = 'NightLife',
  INTERNET_CALL = 'InternetCall',
  RESTAURANT = 'Restaurant',
  TRAVEL = 'Travel'
}

export interface EvaluationCriteria {
  id: string;
  evaluationCategory: EvaluationCategory;
  text: string;
  textEnglish: string;
  question: string;
  displayPosition: number;
  checked: boolean;
}

export interface NavItem {
  category: string;
  name: string;
  description: string;
}

export interface Review {
  id: string;
  createdAt: Date;
  brand: Brand;
  respondent: any;
  reviewType: ReviewType;
}

export interface ReviewComment extends Review {
  url: string;
  source: string;
  username: string;
  comment: string;
  reviewMood: ReviewMood | string;
  title: string;
  lastExperienceDate: string;
}

export interface ReviewRating extends Review {
  rating: number;
}

export interface ReviewVote extends Review {
  evaluationCriteria: EvaluationCriteria;
  rating: number;
  relatedCommentId: string;
}

export enum ReviewMood {
  NEUTRAL = 'Neutral',
  SATISFIED = 'Satisfied',
  DISSATISFIED = 'Dissatisfied',
  UNKNOWN = 'Unknown'
}

export enum ReviewType {
  YES_NO = 'YES_NO',
  RATING_SCALE_5 = 'RATING_SCALE_5',
  RATING_SCALE_10 = 'RATING_SCALE_10',
  COMMENT = 'COMMENT'
}

export interface SatisfactionRanking {
  id: string;
  createdAt: Date;
  brandId: string;
  satisfied: number;
  dissatisfied: number;
  neutral: number;
  notApplicable: number;
  satisfactionRate: number;
}

export interface SurveyRespondent {
  id: string;
  country: string;
  region: string;
  city: string;
  ipAddress: string;
  isMobile: boolean;
  isConfirmed: boolean;
}

export enum SurveyType {
  RATING = 'Rating',
  SINGLE_SELECT = 'SingleSelect',
  MULTI_SELECT = 'MultiSelect'
}

export interface RestApiErrorResponse {
  // Error response class name.
  name?: string | null | undefined;

  // Error response threw message
  message?: string | null | undefined;

  // HTTP error status text
  status: string;

  // Called URL
  url: string;
}

export interface User {
  id: string;
  userRole: UserRole;
  name: string;
  pseudo: string | null | undefined;
  password: string;
  registrationDate: Date | null | undefined;
  lastConnection: Date | null | undefined;
}

export enum UserRole {
  MODERATOR = 'moderator'
}

export const LINK_FILE_SERVER = 'https://file.srv.sleeksys.com/consumer-mind/app/';

export const BRAND_CATEGORIES = [
  {
    name: 'Restaurant',
    image: 'category/restaurant.jpg',
    url: 'restaurant',
    brands: []
  },
  {
    name: 'Hotel',
    image: 'category/hotel.jpg',
    url: 'hotel',
    brands: []
  },
  {
    name: 'Discotheque',
    image: 'category/disco.jpg',
    url: 'night-life',
    brands: []
  },
  {
    name: 'Internet & Call',
    image: 'category/phone.jpg',
    url: 'internet-call',
    brands: []
  },
  {
    name: 'Travel',
    image: 'category/travel.jpg',
    url: 'travel',
    brands: []
  }
];

export interface PopUpMenuItem {
  text: string;
  link?: string | RouterParams | undefined;
  headline?: boolean | undefined;
}

export interface RouterParams {
  path: string;
  query?: any | undefined;
}

export const ENDPOINT_URI_VPS = 'https://api.vps.prod.sleeksys.com';
export const ENDPOINT_URI_AWS = 'https://api.cloud.aws.prod.sleeksys.com';
export const ENDPOINT_URI_GCP = 'https://api.cloud.gcp.prod.sleeksys.com';
