import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
  Brand,
  BrandService,
  EvaluationCriteria,
  EvaluationCriteriaService,
  LINK_FILE_SERVER,
  ReviewComment,
  SatisfactionRanking
} from 'lib-commons';
import {ReviewMood} from '../../../../../lib-commons/src/lib/model/models';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-comparator-brand',
  templateUrl: './brand.component.html',
  styleUrls: []
})
export class BrandComponent implements OnInit {

  public brand: Brand;

  public evaluationCriteria: Promise<EvaluationCriteria[]>;
  public reviews: Promise<ReviewComment[]>;
  public ranking: SatisfactionRanking;
  public reviewsCount = 0;

  public isBotModalOpen: boolean;
  public isMoodModalOpen: boolean;

  private queryCategory: string;
  private queryBrandName: string;

  constructor(private cd: ChangeDetectorRef,
              private router: Router,
              private route: ActivatedRoute,
              private brandService: BrandService,
              private evaluationCriteriaService: EvaluationCriteriaService) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.queryCategory = params.category;
      this.queryBrandName = params.name;
    });

    this.getBrand();
    this.getEvaluationCriteria();
  }

  private getBrand() {
    this.brandService.findByUniqueName(this.queryBrandName)
      .toPromise()
      .then(brand => {
        this.brand = brand;

        this.getLogo();
        this.getReviews();
        this.getRanking();

        this.cd.markForCheck();
      });
  }

  private getEvaluationCriteria() {
    this.evaluationCriteria = this.evaluationCriteriaService.findByCategory(this.queryCategory)
      .toPromise();
  }

  private getLogo() {
    if (this.brand.logoUri === null) {
      this.brandService.findLogo(this.brand)
        .then(logo => {
          this.brand.logoUri = logo
            ? LINK_FILE_SERVER + 'assets/images/providers/' + logo
            : null;
          this.cd.markForCheck();
        });
    }
  }

  private getReviews() {
    this.reviewsCount = 0;
    this.reviews = this.brandService.findReviews(this.brand.id)
      .toPromise();
    if (this.reviews) {
      this.reviews.then(reviews => {
        this.reviewsCount = reviews.length;
        this.cd.markForCheck();
      });
    }
  }

  private getRanking() {
    this.brandService.findSatisfactionRankingByBrand(this.brand.id)
      .toPromise()
      .then(ranking => {
        this.ranking = ranking;
        this.cd.markForCheck();
      });
  }

  public reload() {
    this.router.navigate(['home'])
      .then();
  }

  public getReviewMoodPicture(mood: ReviewMood): string {
    let moodString = mood.toLowerCase();
    moodString = moodString.charAt(0).toUpperCase() + moodString.slice(1);

    let url = 'assets/images/emoji/';
    switch (moodString) {
      case ReviewMood.SATISFIED:
        url += 'emoji_happy.png';
        break;
      case ReviewMood.DISSATISFIED:
        url += 'emoji_unhappy.png';
        break;
      case ReviewMood.NEUTRAL:
        url += 'emoji_neutral.png';
        break;
      default:
        url += 'emoji_unknow.png';
        break;
    }
    return url;
  }

  public onMoodModalClose(event: boolean) {
    if (event) {
      this.isMoodModalOpen = false;
    }
  }

  public onBotModalClose(event: boolean) {
    if (event) {
      this.isBotModalOpen = false;
    }
  }

  public mapCategoryUrl(): string {
    return this.brand ? BrandService.mapToCategoryString(this.brand) : '';
  }
}
