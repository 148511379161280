import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Brand, BrandService, EvaluationCategory, LINK_FILE_SERVER} from 'lib-commons';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-comparator-search',
  templateUrl: './search.component.html',
  styleUrls: []
})
export class SearchComponent implements OnInit {

  public URL_IMAGES = LINK_FILE_SERVER + 'assets/images/';

  public headerTitle: string;

  public brands: Brand[] = [];
  public cities: string[] = [];

  public categories: {
    name: string,
    image: string,
    url: string
  } [];

  private queryParams = {
    category: null,
    location: null
  };

  constructor(private _cd: ChangeDetectorRef,
              private _router: Router,
              private _route: ActivatedRoute,
              private _brandService: BrandService) {
  }

  ngOnInit() {
    this._route.queryParams.subscribe(params => {
      this.queryParams.category = params.c && params.c !== '' ? params.c : null;
      this.queryParams.location = params.l && params.l !== '' ? params.l : null;
      this.init();
    });

    this.categories = [
      {
        name: 'Restaurant',
        image: 'category/restaurant.jpg',
        url: 'restaurant'
      },
      {
        name: 'Hotel',
        image: 'category/hotel.jpg',
        url: 'hotel'
      },
      {
        name: 'Discotheque',
        image: 'category/disco.jpg',
        url: 'night-life'
      },
      {
        name: 'Internet & Call',
        image: 'category/phone.jpg',
        url: 'internet-call'
      },
      {
        name: 'Travel',
        image: 'category/travel.jpg',
        url: 'travel'
      }
    ];
  }

  private init() {
    this.getBrands().then(() => {
      this.getBrandLogos();
    });

    // update header title
    if (this.queryParams.location) {
      this.headerTitle = 'Discover ' + this.queryParams.location;
    }
    if (this.queryParams.category && !this.queryParams.location) {
      switch (this.queryParams.category) {
        case 'internet-call':
          this.headerTitle = 'Internet & Call';
          break;
        case 'travel':
          this.headerTitle = EvaluationCategory.TRAVEL;
          break;
        case 'hotel':
          this.headerTitle = EvaluationCategory.HOTEL;
          break;
        case 'restaurant':
          this.headerTitle = EvaluationCategory.RESTAURANT;
          break;
        case 'night-life':
          this.headerTitle = 'Night-Life';
          break;
        default:
          this.headerTitle = 'All Categories';
          break;
      }
    }
  }

  private async getBrands() {
    const location = this.queryParams.location && this.queryParams.location !== ''
      ? this.queryParams.location
      : null;
    const category = this.queryParams.category && this.queryParams.category !== ''
      ? this.queryParams.category
      : null;

    await this._brandService.findAll()
      .toPromise()
      .then(brands => {
        this.brands = brands
          .filter(brand => {
            // match location
            if (location && (brand.city !== '' && brand.city !== location)) {
              return false;
            }

            // match category
            return !(category && category !== BrandService.mapToCategoryString(brand));
          });

        this.cities = brands
          .map(brand => brand.city)
          .filter((city, index, array) => city !== '' && array.indexOf(city) === index)
          .sort();
      });

    this._cd.markForCheck();
  }

  private getBrandLogos() {
    const getLogoUri = async (brand: Brand): Promise<string> => {
      let uri = null;
      await this._brandService.findLogo(brand).then(logo => {
        uri = logo
          ? LINK_FILE_SERVER + 'assets/images/providers/' + logo
          : null;
      });
      return uri;
    };

    this.brands.forEach(brand => {
      if (brand.logoUri === null) {
        getLogoUri(brand).then(uri => {
          brand.logoUri = uri;
        });
      }
    });

    this._cd.markForCheck();
  }

  public reload() {
    this._router.navigate(['home']);
  }

  public getBrandCategoryUrl(brand: Brand): string {
    return BrandService.mapToCategoryString(brand);
  }
}
